body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
    /*monospace;*/
    font-family: Arial, sans-serif;

    /*background-color: #073256;*/

    /*background: rgb(7,50,86);*/
    /*background: linear-gradient(327deg, rgba(7,50,86,1) 0%, rgba(0,173,208,1) 100%);*/
    /*background-repeat: no-repeat;*/
    /*background-color: rgb(7,50,86);*/
    /*height: 95vh;*/

    /*background-image: url("circle-blues.webp");*/
    /*background-repeat: repeat;*/
    /*background-size: 20%;*/


    background-color: #613c21;
    background-image: url("img/brown_concrete.webp");
    background-repeat: repeat;
    background-size: 10%;

    color: white;
    /*font-size: calc(10px + 1.4vmin);*/
    font-size: 1.5em;
}

body:before {
}

footer {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 0.85em;
    color: darkgray;
}
