body {
  text-align: center;
  padding-top: 2vh;
}

a {
  color: darkgray;
}

.big-image {
    /*border: 1px solid black;*/
    /*filter: drop-shadow(0 0 0.75rem crimson);*/
    filter: drop-shadow(0 0 0.75rem #934b17);
}
